import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { Middleware } from 'redux';
import { EventMiddlewareConfig } from './types';

let eventMapKeys: string[];

/**
 * Produces a middleware that handles pushing events to a data layer
 * @typeParam EventMapper - Mapping of redux action type strings to an analytics event producer
 * @param dataLayer - The dataLayer array of analytics events
 * @returns Middleware function
 */
export const buildGTMEventMiddleware = ({ eventMap, dataLayerName, enabled = true }: EventMiddlewareConfig): Middleware => ({
    getState
}) => (next) => (action: AnyFSA) => {
    next(action);
    if (!enabled) return;
    if (!eventMapKeys) {
        eventMapKeys = Object.keys(eventMap);
    }
    const shouldProccessAction = eventMapKeys.includes(action.type) && window[dataLayerName];
    if (shouldProccessAction) {
        const mappedEvent = eventMap[action.type];
        const event = typeof mappedEvent === 'function' ? mappedEvent(action, getState()) : mappedEvent;
        window[dataLayerName].push(event);
    }
};
