import { AnyFSA } from '@makemydeal/dr-platform-shared';

export enum EventType {
    USER_EVENT = 'userEvent',
    DATA_LAYER_READY = 'dataLayerReady'
}

export type UserEvent = BaseEvent & EventInfo;
export type EventInfo = {
    /** Plain english description of the element with which the user interacted */
    eventElement: string;
    /** The action the user performed, e.g. Clicked */
    eventAction: string;
    /** Plain english description of what occurred after the action took place, e.g. Modal Opened*/
    eventResult: string;
    /** A breadcrumb trail of nested DOM components. These values should be consistent across events such that all user interactions within a given component have the same breadcrumb trail. The top-most item should break down into a reasonable number of application features. E.g. Dashboard > Terms Section > MSRP Input */
    eventLocation: string;
    /** Any quantitative value associated with the action (a count of returned search results, etc). */
    eventValue?: number;
};
export interface BaseEvent {
    event: EventType;
}
export type EventProducer = (action?: AnyFSA, currentState?: any) => UserEvent | BaseDataLayer;
export type EventOrEventProducer = EventProducer | UserEvent;
export type EventMap = Record<string, EventOrEventProducer>;
/** The array used to store analytics events, usually accessed via window.dataLayer */
export type DataLayer = any[];
export type EventMiddlewareConfig = {
    eventMap: EventMap;
    dataLayerName: string;
    enabled?: boolean;
};
// hardcoded per the analytics docs
export type UserType = 'dealer' | 'shopper';

export type DTVersion2Fields = {
    /** V2: A string which indicates the plaintext Bridge username of the active user. */
    bridgeUsername?: string;
    /** V2: A string which uniquely identifies the currently logged in user across the enterprise. Available as the `sub` value in the OAuth2 access token. */
    bridgePlatformId?: string;
    /** V2: A string which uniquely identifies the currently logged in user in your application's context. */
    solutionPrincipalId?: string;
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};

export type DLInput = DTVersion2Fields & {
    userType: UserType;
    isUserInternal: boolean;
    applicationName: string;
    environment: string;
    isProduction: boolean;
};

export type CommonContext = {
    /** The DR Admin Dealer ID */
    dealershipId: number | undefined;
    /** The DR Admin Dealer Name */
    dealershipName: string | undefined;
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};
export type CommonContextV2 = CommonContext & {
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};

export type CommonUser = {
    /** this is Bridge userCode (from SAML) or undefined */
    bridgeUser?: string | undefined;
    /** Unique identifier for the user, like a name */
    applicationUser?: string | undefined;
    /** Dealership user or shopper */
    userType?: UserType;
    isInternalUser?: boolean;
};

export type CommonUserV2 = {
    /** Dealership user or shopper */
    userType?: UserType;
    isInternalUser?: boolean;
    /** V2: A string which indicates the plaintext Bridge username of the active user. */
    bridgeUsername?: string;
    /** V2: A string which uniquely identifies the currently logged in user across the enterprise. Available as the `sub` value in the OAuth2 access token. */
    bridgePlatformId?: string;
    /** V2: A string which uniquely identifies the currently logged in user in your application's context. */
    solutionPrincipalId?: string;
};

export type CommonApplication = {
    businessUnitName: string;
    name: string;
    version: string;
    environment: string;
    isProduction: boolean;
};

export type BaseDataLayer = {
    /** Common to all Cox Auto GTM implementations */
    common?: {
        dataLayerVersion?: number;
        user?: CommonUser | CommonUserV2;
        application?: CommonApplication;
        context?: CommonContext;
    };
    [key: string]: any; // allows consumers to add more data
};
export type DataLayerReadyEvent = BaseDataLayer & {
    event: EventType;
    dataLayerReady: boolean;
};
export type GoogleTagManagerProps = DTVersion2Fields & {
    /** The Google Tag Manager ID */
    gtmId: string;
    applicationName: string;
    /** The persona of the user e.g. dealer, shopper */
    userType: UserType;
    /** Is the user a Cox team member */
    isUserInternal: true | undefined;
    /** The environment e.g. local, dev, production */
    environment: string;
    isProduction: boolean;
    /** Which dataLayer array GTM will use in case there are multiple */
    dataLayerName: string;
    /** The version of the dataLayer */
    version?: number;
};
