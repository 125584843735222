import { UserEvent, BaseDataLayer, DataLayerReadyEvent, EventInfo, EventType } from './types';

/**
 * Builds a user event object
 */
export const buildUserEvent = (eventInput: EventInfo): UserEvent => ({
    event: EventType.USER_EVENT,
    ...eventInput
});

/**
 * Builds a ready event object signifying to GTM that we can now start logging events
 */
export const buildReadyEvent = (eventInput: BaseDataLayer): DataLayerReadyEvent => ({
    event: EventType.DATA_LAYER_READY,
    [EventType.DATA_LAYER_READY]: true,
    ...eventInput
});
