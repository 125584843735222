import { DLInput, BaseDataLayer, GoogleTagManagerProps, CommonApplication, CommonUserV2, CommonContextV2 } from './types';

/**
 * Initializes a data layer event with all required properties present, even if they are undefined
 */
export const buildInitialDataLayerV1 = (input: DLInput): BaseDataLayer => ({
    dataLayerReady: false,
    common: {
        dataLayerVersion: 1,
        user: {
            bridgeUser: undefined,
            applicationUser: undefined,
            userType: input.userType,
            isInternalUser: input.isUserInternal
        },
        application: {
            businessUnitName: 'Digital Retailing',
            name: input.applicationName,
            version: '1.0',
            environment: input.environment,
            isProduction: input.isProduction
        },
        context: {
            dealershipId: undefined,
            dealershipName: undefined
        }
    }
});

export const buildInitialDataLayerV2 = (input: DLInput): BaseDataLayer => ({
    dataLayerReady: false,
    common: {
        dataLayerVersion: 2,
        user: {
            bridgeUsername: input.bridgeUsername,
            bridgePlatformId: input.bridgePlatformId,
            solutionPrincipalId: input.solutionPrincipalId,
            userType: input.userType,
            isInternalUser: input.isUserInternal
        } as CommonUserV2,
        application: {
            businessUnitName: 'Digital Retailing',
            name: input.applicationName,
            version: '1.0',
            environment: input.environment,
            isProduction: input.isProduction
        } as CommonApplication,
        context: {
            dealershipId: undefined,
            dealershipName: undefined,
            commonOrgId: input.commonOrgId
        } as CommonContextV2
    }
});

/** Initializes GoogleTagManager by adding a script to the page and initializing its data layer array */
export const initDrGtm = ({
    gtmId,
    applicationName,
    userType,
    isUserInternal,
    environment,
    isProduction,
    dataLayerName,
    version = 1,
    commonOrgId,
    bridgeUsername,
    bridgePlatformId,
    solutionPrincipalId
}: GoogleTagManagerProps): void => {
    const windowBinding: any = window;
    windowBinding[dataLayerName] = [
        version === 2
            ? buildInitialDataLayerV2({
                  applicationName,
                  userType,
                  isUserInternal,
                  environment,
                  isProduction,
                  commonOrgId,
                  bridgeUsername,
                  bridgePlatformId,
                  solutionPrincipalId
              })
            : buildInitialDataLayerV1({
                  applicationName,
                  userType,
                  isUserInternal,
                  environment,
                  isProduction
              })
    ];
    windowBinding[dataLayerName].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScript = document.getElementsByTagName('head')[0];
    const newScript = document.createElement('script');
    newScript.async = true;
    newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=${dataLayerName}`;
    firstScript.parentNode.insertBefore(newScript, firstScript);
};
